import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./PatientMain.css";
import logo from '../assets/CAROLINA.png';


function SiteMap() {
    const navigate = useNavigate();
    const [dimensions, setDimensions ] = React.useState(window);
    const [colorMode, setColorMode] = React.useState(false);

    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "scroll"
        };
    }, []);
    
    return (
        <div id="body" data-theme={colorMode}>
            <div id="root" data-theme={colorMode} >
                <div className="">
                    <div className="navbar">
                        <div className="navbarImage" style={{width: dimensions.innerWidth/3}}>
                            <img style={{height: '105px', width: '250px'}} src={logo}></img>
                        </div>
                        <div className="navBarActions" style={{width: dimensions.innerWidth/3}}>
                            <button style={{fontFamily: "Poppins, arial, sans-serif"}} className="navbarButton" onClick={() => navigate("/")}>Home</button>
                            <button style={{fontFamily: "Poppins, arial, sans-serif"}} className="navbarButton" onClick={() => navigate("/about-us")}>About Us</button>
                            <button style={{fontFamily: "Poppins, arial, sans-serif"}} className="navbarButton" onClick={() => navigate("/patient-referral")}>Patient Referral</button>
                            <button style={{fontFamily: "Poppins, arial, sans-serif"}} className="navbarButton" onClick={() => navigate("/services")}>Services</button>
                            <button style={{fontFamily: "Poppins, arial, sans-serif"}} className="navbarButton" onClick={() => navigate("/contact-us")}>Contact Us</button>
                        </div>
                    </div>
                    <div className="" style={{display: 'flex', justifyContent: 'center', height: dimensions.innerHeight-(dimensions.innerHeight/5.5), maxHeight: dimensions.innerHeight-(dimensions.innerHeight/5.5), maxWidth: dimensions.innerWidth}}>
                        <div className="mb-3" style={{alignItems: 'center', width: '700px'}} >
                            <label id="patientReferralTitle">Sitemap</label>
                            <div id="page_selection">
                                <div className="oneColumnContainer">
                                    <button className="siteMapButton" onClick={() => navigate("/")}>https://carolinaomfimaging.com/</button>
                                    <button className="siteMapButton" onClick={() => navigate("/about-us")}>https://carolinaomfimaging.com/about-us</button>
                                    <button className="siteMapButton" onClick={() => navigate("/patient-referral")}>https://carolinaomfimaging.com/patient-referral</button>
                                    <button className="siteMapButton" onClick={() => navigate("/about-us")}>https://carolinaomfimaging.com/about-us</button>
                                    <button className="siteMapButton" onClick={() => navigate("/services")}>https://carolinaomfimaging.com/services</button>
                                    <button className="siteMapButton" onClick={() => navigate("/cbct-radiology-reports")}>https://carolinaomfimaging.com/cbct-radiology-reports</button>
                                    <button className="siteMapButton" onClick={() => navigate("/implant-planning-software")}>https://carolinaomfimaging.com/implant-planning-software</button>
                                    <button className="siteMapButton" onClick={() => navigate("/cbct-education")}>https://carolinaomfimaging.com/contact-us</button>
                                    <button className="siteMapButton" onClick={() => navigate("/contact-us")}>https://carolinaomfimaging.com/cbct-radiology-reports</button>
                                    <button className="siteMapButton" onClick={() => navigate("/privacy-policy")}>https://carolinaomfimaging.com/privacy-policy</button>
                                    <button className="siteMapButton" onClick={() => navigate("/site-map")}>https://carolinaomfimaging.com/site-map</button>
                                </div>
                            </div>
                            <div className="footer">
                                <label className="footerButton">Copyright © Carolina OMF Imaging - All Rights Reserved.</label>
                                <button className="footerButton" onClick={() => navigate("/privacy-policy")}>Privacy Policy</button>
                                <button className="footerButton" onClick={() => navigate("/site-map")}>Sitemap</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SiteMap;