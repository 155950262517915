import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./PatientMain.css";
import logo from '../assets/CAROLINA.png';


function Home() {
    const navigate = useNavigate();
    const [dimensions, setDimensions ] = React.useState(window);
    const [navigationPage, setNavigationPage] = React.useState('');
    const [account, setAccount] = React.useState(false);
    const [colorMode, setColorMode] = React.useState(false);

    const imageSlides = [
        {
            label: "Annotated Images in Reports",
            imgPath: "https://img1.wsimg.com/isteam/ip/3e153f97-75cf-441a-a94f-4351659383d5/blob-0024.png/:/cr=t:0%25,l:0%25,w:100%25,h:100%25"
        },
        {
            label: "Implant Guided Software",
            imgPath: "https://img1.wsimg.com/isteam/ip/3e153f97-75cf-441a-a94f-4351659383d5/blob-0025.png/:/cr=t:0%25,l:0%25,w:100%25,h:100%25/rs=w:1968,h:996"
        },
        {
            label: "CBCT Radiology Report",
            imgPath: "https://img1.wsimg.com/isteam/ip/3e153f97-75cf-441a-a94f-4351659383d5/Garlitz_Image_Website.JPG/:/cr=t:0%25,l:0%25,w:100%25,h:100%25/rs=w:984,h:422"
        },
        {
            label: "CBCT Education",
            imgPath: "https://img1.wsimg.com/isteam/ip/3e153f97-75cf-441a-a94f-4351659383d5/blob-0020.png/:/cr=t:0%25,l:0%25,w:100%25,h:100%25/rs=w:984,h:474"
        }
    ]
    const [currentIndex, setCurrentIndex] = React.useState(0);
    const [listImageLength, setLength] = React.useState(imageSlides.length);

    useEffect(() => {
        // axios.post("https://ipinfo.io" )
        // .then((response) => {
        //     setIP(response.data.ip);
        // })
        // .catch((error) => {
        //     console.error("Error uploading the file:", error);
        // });

        // const interval = setInterval(() => {
        //     currentIndex((prevIndex) => (prevIndex + 1) % imageSlides.length);
        // }, 300);

        setLength(imageSlides.length);

        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "scroll"
        };
    }, [imageSlides]);

    const handleAccount = () => {
        setAccount(!account);
    };

    const onChangeColorMode = () => {
        setColorMode(!colorMode);
    }
    
    const next = () => {
        if (currentIndex < (listImageLength - 1)) {
            setCurrentIndex(prevState => prevState + 1)
        }
    }

    const prev = () => {
        if (currentIndex > 0) {
            setCurrentIndex(prevState => prevState - 1)
        }
    }

    return (
        <div id="body" data-theme={colorMode}>
            <div className="page">
                <div className="navbar">
                    <div className="navbarImage" style={{width: dimensions.innerWidth/3}}>
                        <img style={{height: '105px', width: '250px'}} src={logo}></img>
                    </div>
                    <div className="navBarActions" style={{width: dimensions.innerWidth/3}}>
                        <button style={{fontFamily: "Poppins, arial, sans-serif"}} className="navbarButton" onClick={() => navigate("/")}>Home</button>
                        <button style={{fontFamily: "Poppins, arial, sans-serif"}} className="navbarButton" onClick={() => navigate("/about-us")}>About Us</button>
                        <button style={{fontFamily: "Poppins, arial, sans-serif"}} className="navbarButton" onClick={() => navigate("/patient-referral")}>Patient Referral</button>
                        <button style={{fontFamily: "Poppins, arial, sans-serif"}} className="navbarButton" onClick={() => navigate("/services")}>Services</button>
                        <button style={{fontFamily: "Poppins, arial, sans-serif"}} className="navbarButton" onClick={() => navigate("/contact-us")}>Contact Us</button>
                    </div>
                </div>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div className="mb-3">
                        <label id="patientReferralTitle">Carolina Oral and Maxillofacial Radiology</label>
                        <div id="page_selection">
                            <div id="homeContainer">
                                <div className="carousel-wrapper">
                                    {
                                        currentIndex > 0 &&
                                        <button onClick={prev} className="left-arrow">
                                            &lt;
                                        </button>
                                    }
                                    <div className="carousel-content-wrapper">
                                        <div className="carousel-content" style={{alignItems: "center", width: '750px'}}>
                                            <div className="column">
                                                <img style={{height: '400px', width: '750px', alignItems: "center"}} src={imageSlides[currentIndex].imgPath}/>
                                                <label id="imageSubLabel" >{imageSlides[currentIndex].label}</label>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        currentIndex < (listImageLength - 1) &&
                                        <button onClick={next} className="right-arrow">
                                            &gt;
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="footer">
                            <label className="footerButton">Copyright © Carolina OMF Imaging - All Rights Reserved.</label>
                            <button className="footerButton" onClick={() => navigate("/privacy-policy")}>Privacy Policy</button>
                            <button className="footerButton" onClick={() => navigate("/site-map")}>Sitemap</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home;