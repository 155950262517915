import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import logo from '../assets/CAROLINA.png';
import logo_data from '../assets/logo_data_mng.png';
import { BsFiletypeDoc, BsFileZip, BsFiletypePdf, BsCloudMinus } from "react-icons/bs";
import "./PatientReferralManagement.css";

import { Amplify } from 'aws-amplify';
import { getCurrentUser, signOut } from 'aws-amplify/auth';
import outputs from '../amplify_outputs.json';
import '@aws-amplify/ui-react/styles.css';
Amplify.configure(outputs);


function Management() {
    const navigate = useNavigate();
    const [userAuthStatus, setUserAuthStatus] = React.useState('');
    const [alertTimer, setAlertTimer] = useState(false);
    const [userError, setUserError] = React.useState('');
    const [dimensions, setDimensions ] = React.useState(window);
    const [ip, setIP] = React.useState('');
    const [dataload, setDataload] = React.useState([]);
    const [droppedFiles, setDroppedFiles] = React.useState('');
    const [progress, setProgress] = React.useState(0);
    const [recordId, setRecordID] = React.useState('');
    const [showUpload, setShowUpload] = React.useState(false);
    const [focusColor, setFocusColor] = React.useState(false);
    
    const changeFavicon = () => {
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.getElementsByTagName('head')[0].appendChild(link);
        }
        link.href = logo_data;
    };

    const findUserAuthenticationStatus = async () => {
        try {
            const { username, userId, signInDetails } = await getCurrentUser();
            setUserAuthStatus(await getCurrentUser());
        } catch {
            navigate("/sign-in");
        }
    }

    async function signOutHandle() {
        try {
            await signOut().then(navigate("/sign-in"));
        }
        catch {
            alert("Oops an error happened with signing out, please try again");
        }
    }

    useEffect(() => {
        pageLoad();
        changeFavicon();
        findUserAuthenticationStatus();

        axios.post("https://ipinfo.io" )
        .then((response) => {
            setIP(response.data.ip);
        })
        .catch((error) => {
            console.error("Error uploading the file:", error);
        });

        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "scroll"
        };
    }, [alertTimer]);

    const [account, setAccount] = React.useState(false);
    const [colorMode, setColorMode] = React.useState(false);

    const handlePopUp = () => {
        setTimeout(() => {
            setAlertTimer(false);
        }, 3000);
    }

    const handleAccount = () => {
        setAccount(!account);
    };

    const onChangeColorMode = () => {
        setColorMode(!colorMode);
    }
        
    const pageLoad = () => {
        let percentage = 0;
        axios.post("https://zx6cnm6tid.execute-api.us-east-1.amazonaws.com/DEV/data/", 
            {
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round(
                      (progressEvent.loaded * 100) / progressEvent.total
                    );
                    if (percentCompleted !== percentage) {
                        percentage = percentCompleted
                        setProgress(percentage);
                    }
                },
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "*"
                }
            }
        ).then((response) => {
            setDataload(response.data.body.sort((i, j) => i.reviewed - j.reviewed));
        }).catch((response) => {
            alert("Oops an error happened with loading data, please try again");
        });
    };

    const handleReviewChange = async (id, reviewed) => {
        await axios.post(
            'https://zx6cnm6tid.execute-api.us-east-1.amazonaws.com/DEV/psurls/',
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "*"
                },
                body: {
                    fileID: id,
                    fileOperation: "reviewed",
                    reviewed: !reviewed
                }
            }
        ).then((response) => {
            pageLoad();
        }).catch((response) => {
            alert("Oops an error happened, please try again");
        });
    }

    const itemUpload = async (event) => {
        event.preventDefault();

        const files = event.target.files;

        if (files && files.length) {
            setDroppedFiles(event.target.files);
        }

        try {
            let percentage = 0;
            const response = await axios.post(
                'https://zx6cnm6tid.execute-api.us-east-1.amazonaws.com/DEV/psurls/',
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "*"
                    },
                    body: {
                        fileID: recordId,
                        fileName: 'finalDoc.pdf',
                        fileType: droppedFiles.type,
                        fileOperation: "getSignedUrl",
                    }
                }
            ).catch((response) => {
                alert("Oops an error happened, please try again");
            });;

            await axios.put(response.data.body, 
                files, 
                {
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round(
                          (progressEvent.loaded * 100) / progressEvent.total
                        );
                        if (percentCompleted !== percentage) {
                            percentage = percentCompleted
                            setProgress(percentage);
                        }
                    },
                    headers: {
                        "Content-Type": 'application/pdf',
                    },
                }
            ).then((res) => {
                pageLoad();
                if (res.statusText !== 'OK') {
                    alert("Referral file NOT submitted, Please try again.");
                }
            }).catch((response) => {
                alert("Oops an error happened, please try again");
            });
        } catch (error) {
            alert("Oops an error happened, please try again");
        }
    }

    const itemDelete = async (id) => {
        let percentage = 0;
        if (window.confirm("Are you sure you want to Delete these records?")) {
            await axios.post(
                'https://zx6cnm6tid.execute-api.us-east-1.amazonaws.com/DEV/psurls/',
                {
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round(
                          (progressEvent.loaded * 100) / progressEvent.total
                        );
                        if (percentCompleted !== percentage) {
                            percentage = percentCompleted
                            setProgress(percentage);
                        }
                    },
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "*"
                    },
                    body: {
                        fileID: id,
                        fileOperation: "delete",
                    }
                }
            ).then((response) => {
                pageLoad();
            }).catch((response) => {
                alert("Oops an error happened, please try again");
            });
        }
    }

    const itemDeletePDF = async (id) => {
        if (window.confirm("Are you sure you want to Delete this PDF file?")) {
            await axios.post(
                'https://zx6cnm6tid.execute-api.us-east-1.amazonaws.com/DEV/psurls/',
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "*"
                    },
                    body: {
                        fileID: id,
                        fileOperation: "deletePDF",
                    }
                }
            ).then((response) => {
                pageLoad();
            }).catch((response) => {
                alert("Oops an error happened, please try again");
            });
        }
    }

    const itemDownload = async (id, file) => {
        try {
            const response = await axios.post(
            'https://zx6cnm6tid.execute-api.us-east-1.amazonaws.com/DEV/psurls/',
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "*"
                },
                body: {
                    fileID: id,
                    fileName: file,
                    fileOperation: "download",
                }
            }).catch((response) => {
                alert("Oops an error happened, please try again");
            });
            const presignedUrl = response.data.body;
            const link = document.createElement('a');
            link.href = presignedUrl;
            link.setAttribute('download', file);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            alert("Oops an error happened, please try again");
        }
    }

    const toggleUpload = async (id) => {
        setShowUpload(!showUpload);
    };

    return (
        <div id="body" data-theme={colorMode}>
            <div className="page">
                <div className="navbar">
                    <div className="navbarImage" style={{width: dimensions.innerWidth/3}}>
                        <img alt="OMF" style={{height: '105px', width: '250px'}} src={logo}></img>
                    </div>
                    <div className="" style={{width: dimensions.innerWidth/3}}>
                        <div className="accountContainer">
                            <button className="accountItems" onClick={handleAccount}>Account</button>
                            {account ? (
                                <> 
                                    <button className="accountItems" onClick={() => {handlePopUp(); setAlertTimer(true);}}>{ip}</button>
                                    {/* <button className="accountItems" onClick={onChangeColorMode} onChange={({ target }) => setColorMode(target.checked)}>
                                        {colorMode ? 
                                            <FaSun className="accountIcon" size={22}/>
                                            : 
                                            <FaMoon className="accountIcon" size={22}/>
                                            }
                                    </button> */}
                                    <button className="accountItems" type="button" onClick={signOutHandle}>Logout</button>
                                </>
                            ) : null}
                        </div>
                    </div>
                </div>
                <div className="container1">
                    <div className="" id="file_output">
                        <div className="mb-3" style={{height: 'auto', width: 'auto'}} >
                            <label htmlFor="formFile" id="mainLabel">Not Reviewed</label>
                            <div className="">
                                <div className="itemRowTitleContainer" style={{backgroundColor: '#F7F8F9', border: 'None', borderTopRightRadius: '3px'}}>
                                    <div id="itemColumn">ID</div>
                                    <div id="itemColumnDate">Date</div>
                                    <div id="itemColumn">Patient Name</div>
                                    <div id="itemColumn">Practitioner</div>
                                    <div id="itemColumnContactEmail">Contact Email</div>
                                    <div id="itemColumn">Report Plus</div> 
                                    <div id="itemColumn">Payment Method</div> 
                                    <div id="itemControlsLabels">Reviewed</div>
                                    <div id="itemControlsLabels">Upload</div> 
                                    <div id="itemControlsLabels">Generate</div> 
                                    <div id="itemControlsLabels">PDF</div>
                                    <div id="itemControlsLabels">Delete</div> 
                                </div>
                                {dataload.length > 0 ? 
                                    <div className="rowHold" style={{alignItems: "center", height: '250px', maxHeight: "500px", overflowY: 'scroll', borderBottomLeftRadius: '3px', borderBottomRightRadius: '3px', border: '.25px #d9dadc solid'}}>
                                        {dataload.map((item, index) => {
                                            if (item.reviewed === false) {
                                                return (
                                                <div key={index}>
                                                    <div style={{backgroundColor: (focusColor && item.id === recordId) ? "#F7F8F9" : "transparent"}} key={item.id}>
                                                        <div className="itemRowContainer">
                                                            <div id="itemRow">{item.id}</div>
                                                            <div id="itemRowDate">{item.dttm}</div>
                                                            <div id="itemRow">{item.patient_name}</div>
                                                            <div id="itemRow">{item.practitioner_name}</div>
                                                            <div id="itemRowContactEmail">{item.contact_email}</div>
                                                            <div id="itemRow">{item.report_plus}</div>
                                                            <div id="itemRow">{item.payment_method}</div> 
                                                            <div id="itemControlsButtons">
                                                                <input type="checkbox" onChange={e => handleReviewChange(item.id, item.reviewed)} defaultChecked={item.reviewed}/>
                                                            </div>
                                                            <div id="itemControlsButtons">
                                                                <button style={{border: "none", backgroundColor: "transparent"}} onClick={() => itemDownload(item.id, item.submittedDoc.submittedDoc)}>
                                                                    <BsFileZip id="itemControlsButtons"/>
                                                                </button>
                                                            </div>
                                                            <div id="itemControlsButtons">
                                                                <button style={{border: "none", backgroundColor: "transparent"}} onClick={() => itemDownload(item.id, item.generatedDoc.generatedDoc)}>
                                                                    <BsFiletypeDoc id="itemControlsButtons"/> 
                                                                </button>
                                                            </div>
                                                            <div id="itemControlsButtonsUpload">
                                                                <button htmlFor="file_picker" style={{border: "none", backgroundColor: "transparent"}} 
                                                                    onClick={() => {
                                                                        toggleUpload(item.id); 
                                                                        setRecordID(item.id);
                                                                        setFocusColor(!focusColor)
                                                                    }}>
                                                                    <BsFiletypePdf id="itemControlsButtonsUploadPDF"/>
                                                                </button>
                                                            </div>
                                                            <div id="itemControlsButtonsDelete">
                                                                <button style={{border: "none", backgroundColor: "transparent"}} onClick={() => itemDelete(item.id)}>
                                                                    <BsCloudMinus id="itemControlsButtonsDelete"/>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {(showUpload && item.id === recordId) && (
                                                        <div className="uploadModal">
                                                            {item?.finalDoc 
                                                            ? 
                                                            <div style={{display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', height: 'auto', width: '300px'}}> 
                                                                <button htmlFor="file_picker" className="uploadItems" style={{}} 
                                                                    onClick={() => {itemDownload(item.id, item?.finalDoc.finalDoc)}}>
                                                                    Download PDF
                                                                </button>
                                                                <button className="uploadItems" style={{}} onClick={() => itemDeletePDF(item.id)}>
                                                                    Delete PDF
                                                                </button>
                                                            </div>
                                                            :
                                                            <div style={{display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', height: 'auto', width: '600px'}}> 
                                                                <label className="uploadModalLabel">Upload your PDF</label>
                                                                <input id="file_picker" className="form-control" style={{display: "flex", width: '400px'}} name="file" type="file" onChange={itemUpload}/>
                                                            </div>
                                                            }
                                                            <div className="uploadFileContainer">
                                                                <button className="uploadItems" id="uploadItems" type="submit" onClick={toggleUpload}>Submit</button>
                                                                <button className="uploadItems" id="uploadItems" type="cancel" onClick={toggleUpload}>Cancel</button>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                )
                                            }
                                        })}
                                    </div>
                                :
                                    <div className="rowHold" style={{alignItems: "center", height: '250px', maxHeight: "500px", overflowY: 'scroll', borderBottomLeftRadius: '3px', borderBottomRightRadius: '3px', border: '.25px #d9dadc solid'}} >
                                        <div style={{height: '250px', width: 'auto', display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}} >
                                            <label id="formLabel" className="form-label" >Patient Submissions Loading...</label>
                                        </div>
                                    </div>
                                }
                            </div>
                            <label htmlFor="formFile" id="mainLabel">Reviewed</label>
                            <div className="reviewContiner">
                                <div className="itemRowTitleContainer" style={{backgroundColor: '#F7F8F9', border: 'None', borderTopRightRadius: '3px', marginBottom: '0px'}}>
                                    <div id="itemColumn">ID</div>
                                    <div id="itemColumnDate">Date</div> 
                                    <div id="itemColumn">Patient Name</div>
                                    <div id="itemColumn">Practitioner</div>
                                    <div id="itemColumnContactEmail">Contact Email</div>
                                    <div id="itemColumn">Report Plus</div> 
                                    <div id="itemColumn">Payment Method</div> 
                                    <div id="itemControlsLabels">Reviewed</div>
                                    <div id="itemControlsLabels">Upload</div> 
                                    <div id="itemControlsLabels">Generate</div> 
                                    <div id="itemControlsLabels">PDF</div>
                                    <div id="itemControlsLabels">Delete</div> 
                                </div>
                                {dataload.length > 0 ?
                                    <div className="rowHold" style={{alignItems: "center", height: '250px', maxHeight: "500px", overflowY: 'scroll', borderBottomLeftRadius: '3px', borderBottomRightRadius: '3px', border: '.25px #d9dadc solid' }}>
                                        {dataload.map((item, index) => {
                                            if (item.reviewed === true) {
                                                return (
                                                <div key={index}>
                                                    <div style={{backgroundColor: (focusColor && item.id === recordId) ? "#F7F8F9" : "transparent" }} key={item.id}>
                                                        <div className="itemRowContainer">
                                                            <div id="itemRow">{item.id}</div>
                                                            <div id="itemRowDate">{item.dttm}</div>
                                                            <div id="itemRow">{item.patient_name}</div>
                                                            <div id="itemRow">{item.practitioner_name}</div>
                                                            <div id="itemRowContactEmail">{item.contact_email}</div>
                                                            <div id="itemRow">{item.report_plus}</div>
                                                            <div id="itemRow">{item.payment_method}</div> 
                                                            <div id="itemControlsButtons">
                                                                <input type="checkbox" onChange={e => handleReviewChange(item.id, item.reviewed)} defaultChecked={item.reviewed}/>
                                                            </div>
                                                            <div id="itemControlsButtons">
                                                                <button style={{border: "none", backgroundColor: "transparent"}} onClick={() => itemDownload(item.id, item.submittedDoc.submittedDoc)}>
                                                                    <BsFileZip id="itemControlsButtons"/> 
                                                                </button>
                                                            </div>
                                                            <div id="itemControlsButtons">
                                                                <button style={{border: "none", backgroundColor: "transparent"}} onClick={() => itemDownload(item.id, item.generatedDoc.generatedDoc)}>
                                                                    <BsFiletypeDoc id="itemControlsButtons"/> 
                                                                </button>
                                                            </div>
                                                            <div id="itemControlsButtonsUpload">
                                                                <button htmlFor="file_picker" style={{border: "none", backgroundColor: "transparent"}} 
                                                                    onClick={() => {
                                                                        toggleUpload(item.id); 
                                                                        setRecordID(item.id);
                                                                        setFocusColor(!focusColor)
                                                                    }}>
                                                                    <BsFiletypePdf id="itemControlsButtonsUploadPDF"/>
                                                                </button>
                                                            </div>
                                                            <div id="itemControlsButtonsDelete">
                                                                <button style={{border: "none", backgroundColor: "transparent"}} onClick={() => itemDelete(item.id)}>
                                                                    <BsCloudMinus id="itemControlsButtonsDelete"/>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {(showUpload && item.id === recordId) && (
                                                        <div className="uploadModal">
                                                            {item?.finalDoc 
                                                            ? 
                                                            <div style={{display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', height: 'auto', width: '300px'}}> 
                                                                <button htmlFor="file_picker" className="uploadItems" style={{}} 
                                                                    onClick={() => {itemDownload(item.id, item?.finalDoc.finalDoc)}}>
                                                                    Download PDF
                                                                </button>
                                                                <button className="uploadItems" style={{}} onClick={() => itemDeletePDF(item.id)}>
                                                                    Delete PDF
                                                                </button>
                                                            </div>
                                                            :
                                                            <div style={{display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', height: 'auto', width: '600px'}}> 
                                                                <label className="uploadModalLabel">Upload your PDF</label>
                                                                <input id="file_picker" className="form-control" style={{display: "flex", width: '400px'}} name="file" type="file" onChange={itemUpload}/>
                                                            </div>
                                                            }
                                                            <div className="uploadFileContainer">
                                                                <button className="uploadItems" id="uploadItems" type="submit" onClick={toggleUpload}>Submit</button>
                                                                <button className="uploadItems" id="uploadItems" type="cancel" onClick={toggleUpload}>Cancel</button>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                )
                                            }
                                        })}
                                    </div>
                                :
                                    <div style={{alignItems: "center", height: '250px', maxHeight: "500px", overflowY: 'scroll', borderBottomLeftRadius: '3px', borderBottomRightRadius: '3px', border: '.25px #d9dadc solid'}} >
                                        <div style={{height: '250px', width: 'auto', display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}} >
                                            <label id="formLabel" className="form-label" >Patient Submissions Loading...</label>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Management;