import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./PatientMain.css";
import logo from '../assets/CAROLINA.png';


function AboutUs() {
    const navigate = useNavigate();
    const [dimensions, setDimensions ] = React.useState(window);
    const [colorMode, setColorMode] = React.useState(false);

    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "scroll"
        };
    }, []);

    return (
        <div id="body">
            <div className="page">
                <div className="navbar">
                    <div className="navbarImage" style={{width: dimensions.innerWidth/3}}>
                        <img style={{height: '105px', width: '250px'}} src={logo}></img>
                    </div>
                    <div className="navBarActions" style={{width: dimensions.innerWidth/3}}>
                        <button style={{fontFamily: "Poppins, arial, sans-serif"}} className="navbarButton" onClick={() => navigate("/")}>Home</button>
                        <button style={{fontFamily: "Poppins, arial, sans-serif"}} className="navbarButton" onClick={() => navigate("/about-us")}>About Us</button>
                        <button style={{fontFamily: "Poppins, arial, sans-serif"}} className="navbarButton" onClick={() => navigate("/patient-referral")}>Patient Referral</button>
                        <button style={{fontFamily: "Poppins, arial, sans-serif"}} className="navbarButton" onClick={() => navigate("/services")}>Services</button>
                        <button style={{fontFamily: "Poppins, arial, sans-serif"}} className="navbarButton" onClick={() => navigate("/contact-us")}>Contact Us</button>
                    </div>
                </div>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div className="mb-3">
                        <label id="patientReferralTitle">Oral and Maxillofacial Radiologists</label>
                        <div id="page_selection">
                            <div style={{width: '700px'}} >
                                <div id="aboutImageContainer">
                                    <img id="aboutImage" src="https://img1.wsimg.com/isteam/ip/3e153f97-75cf-441a-a94f-4351659383d5/0eb3864d-d6d2-478b-91f1-a25bc65d7a88.jpg/:/cr=t:0%25,l:0%25,w:100%25,h:100%25/rs=w:730,cg:true"></img>
                                </div>
                                <div className="column">
                                    <label id="formSubLabel" className="form-label">W. BRUCE HOWERTON, JR., DDS, MS</label>
                                    <label id="formEmailLabel" className="form-label">Dr. Howerton received a DDS degree from West Virginia University School of Dentistry in 1985. 
                                        He completed a certificate of Endodontics from the University Of North Carolina School Of Dentistry in 1987 and practiced surgical and nonsurgical endodontics in Asheville, North Carolina. 
                                        In 1999, he entered the UNC Oral and Maxillofacial Radiology graduate program and completed a Master of Science in 2001. 
                                        In addition to becoming an Oral and Maxillofacial Radiologist, he became proficient in Web development and discovered novel forms of content delivery using authoring software. 
                                        While serving on the faculty at The UNC School of Dentistry, he presented novel forms of content to faculty, staff and students, and aided in Web development. 
                                        As of December 2003, Dr. Howerton became a Diplomate of the American Academy of Oral and Maxillofacial Radiology. 
                                        He has published numerous articles regarding CBCT in dentistry. 
                                        Dr. Howerton has been a featured speaker for the Pankey Institute in Key Biscayne, Florida and also for the KaVo Kerr 3D Imaging Congress over the last 8 years. 
                                        Dr. Howerton has been a two-term board member of the IAC (Inter-Societal Accreditation Commission) for Dental CT. 
                                        He also speaks throughout North Carolina as well as large venues throughout the United States. 
                                        Dr. Howerton has been in private OMR practice in Raleigh, NC since 2004 and has  overseen 3D Imaging satellite facilities throughout North Carolina.</label>
                                </div>
                                <div id="aboutImageContainer" style={{ alignSelf: 'center'}} >
                                    <img id="aboutImage" src="https://img1.wsimg.com/isteam/ip/3e153f97-75cf-441a-a94f-4351659383d5/unnamed.jpg/:/rs=w:730,cg:true,m"></img>
                                </div>
                                <div className="column">
                                    <label id="formSubLabel" className="form-label">Nicholas Colvin, DDS</label>
                                    <label id="formEmailLabel" className="form-label">Dr. Nick Colvin is an Athens, GA native who attended Wofford College in Spartanburg, SC to play football and pursue a degree in Biology. 
                                        He then obtained his D.D.S. at the University of North Carolina in Chapel Hill. During this time, Dr. Colvin met Dr. Howerton and discovered his passion for oral and maxillofacial radiology. 
                                        They shared a common philosophy in patient care and serving the dental community as radiologists. This common mindset led to a long-term plan of partnering and practicing together. 
                                        Following dental school, Dr. Colvin completed the Oral and Maxillofacial Radiology residency program at the University of California, Los Angeles. 
                                        During his residency training, Dr. Colvin served as chief resident and pursued research assessing quality assurance protocols in monitors used to evaluate radiographic images.
                                        In his free time, Dr. Colvin enjoys taking on outdoor challenges, such as hiking the Appalachian Trail and biking the Great Divide. He also enjoys spending time with his family, especially getting to be the “fun uncle” to his nephews Archer and Owen. </label>
                                </div>
                                <div id="aboutImageContainer" style={{ alignSelf: 'center'}} >
                                    <img id="aboutImage" src="https://img1.wsimg.com/isteam/ip/3e153f97-75cf-441a-a94f-4351659383d5/blob-4e88225.png/:/cr=t:0%25,l:0%25,w:100%25,h:100%25/rs=w:524,cg:true/fx-gs"></img>
                                </div>
                            </div>
                        </div>
                        <div className="footer">
                            <label className="footerButton">Copyright © Carolina OMF Imaging - All Rights Reserved.</label>
                            <button className="footerButton" onClick={() => navigate("/privacy-policy")}>Privacy Policy</button>
                            <button className="footerButton" onClick={() => navigate("/site-map")}>Sitemap</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUs