import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./PatientMain.css";
import logo from '../assets/CAROLINA.png';


function CBCTEducation() {
    const navigate = useNavigate();
    const [dimensions, setDimensions ] = React.useState(window);
    const [account, setAccount] = React.useState(false);
    const [colorMode, setColorMode] = React.useState(false);

    useEffect(() => {

        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "scroll"
        };
    }, []);

    const handleAccount = () => {
        setAccount(!account);
    };

    const onChangeColorMode = () => {
        setColorMode(!colorMode);
    }


    return (
        <div id="body" data-theme={colorMode}>
            <div id="root" data-theme={colorMode} >
                <div className="">
                    <div className="navbar">
                        <div className="navbarImage" style={{width: dimensions.innerWidth/3}}>
                            <img style={{height: '105px', width: '250px'}} src={logo}></img>
                        </div>
                        <div className="navBarActions" style={{width: dimensions.innerWidth/3}}>
                            <button style={{fontFamily: "Poppins, arial, sans-serif"}} className="navbarButton" onClick={() => navigate("/")}>Home</button>
                            <button style={{fontFamily: "Poppins, arial, sans-serif"}} className="navbarButton" onClick={() => navigate("/about-us")}>About Us</button>
                            <button style={{fontFamily: "Poppins, arial, sans-serif"}} className="navbarButton" onClick={() => navigate("/patient-referral")}>Patient Referral</button>
                            <button style={{fontFamily: "Poppins, arial, sans-serif"}} className="navbarButton" onClick={() => navigate("/services")}>Services</button>
                            <button style={{fontFamily: "Poppins, arial, sans-serif"}} className="navbarButton" onClick={() => navigate("/contact-us")}>Contact Us</button>
                        </div>
                    </div>
                    <div className="" style={{display: 'flex', justifyContent: 'center', height: dimensions.innerHeight-(dimensions.innerHeight/5.5), maxHeight: dimensions.innerHeight-(dimensions.innerHeight/5.5), maxWidth: dimensions.innerWidth, overflowY: 'scroll'}}>
                        <div className="mb-3">
                            <label id="patientReferralTitle">CBCT Continuing Education</label>
                            <div id="page_selection">
                                <div className="twoColumnContainer">
                                    <div className="sharingServicesContainer">
                                        <img id="eduImage" src="https://img1.wsimg.com/isteam/ip/3e153f97-75cf-441a-a94f-4351659383d5/Teaching%20Harry%20-%20Go%20Daddy.jpg/:/cr=t:0%25,l:1.81%25,w:96.38%25,h:100%25/rs=w:1189,h:595,cg:true"></img>
                                    </div>
                                    <div className="sharingServicesContainer">
                                        <div>
                                            <label id="formServiceLabel">Dr. Howerton has been teaching CBCT education for over 20 years.</label>
                                            <label id="formServiceLabel">He has concentrated imaging and interpretation information into 2 one-hour sessions focusing on:</label>
                                            <br></br>
                                            <label id="formServiceLabel">1. Image Quality [maximizing pre-scan controlled and uncontrolled variables as well as viewing software operations]</label>
                                            <label id="formServiceLabel">2. Proper Systematic Review of Data as well as use of proper descriptive language [compared to two-dimensional intraoral and extraoral imaging]</label>
                                            <label id="formServiceLabel">3. Examples of Common Findings/Pathology including reconstruction artifacts </label>
                                            <label id="formServiceLabel">4. A course outline will be provided before each session. The fee is $650 per session for up to three attendees.</label>
                                        </div>
                                    </div>
                                </div>
                                <div id="radiologyImageContainer">
                                    <img id="adaImage" src="https://img1.wsimg.com/isteam/ip/3e153f97-75cf-441a-a94f-4351659383d5/blob-0017.png/:/cr=t:0%25,l:0%25,w:100%25,h:100%25"></img>
                                </div>
                            </div>
                            <div className="footer">
                                <label className="footerButton">Copyright © Carolina OMF Imaging - All Rights Reserved.</label>
                                <button className="footerButton" onClick={() => navigate("/privacy-policy")}>Privacy Policy</button>
                                <button className="footerButton" onClick={() => navigate("/site-map")}>Sitemap</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CBCTEducation;