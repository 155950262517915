import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min";

import PatientReferralForm from "./pages/PatientReferralForm";
import PatientReferralManagement from "./pages/PatientReferralManagement";
import PatientHome from "./pages/PatientHome";
import PatientAboutUs from "./pages/PatientAboutUs";
import PatientServices from "./pages/PatientServices";
import CBCTRadiologyReports from "./pages/CBCTRadiologyReports";
import ImplantPlanningSoftware from "./pages/ImplantPlanningSoftware";
import CBCTEducation from "./pages/CBCTEducation";
import PatientContactUs from "./pages/PatientContactUs";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import SiteMap from "./pages/Sitemap";
import SignIn from "./comp/SignIn";


function App() {
    
    return (
    <BrowserRouter>
        <Routes>
            <Route path="/patient-referral" element={<PatientReferralForm/>} />
            <Route path='/data' element={<PatientReferralManagement/>}/>
            <Route path='/sign-in' element={<SignIn/>}/>
            <Route path='/' element={<PatientHome/>}/>
            <Route path='/about-us' element={<PatientAboutUs/>}/>
            <Route path='/services' element={<PatientServices/>}/>
            <Route path='/cbct-radiology-reports' element={<CBCTRadiologyReports/>}/>
            <Route path='/implant-planning-software' element={<ImplantPlanningSoftware/>}/>
            <Route path='/cbct-education' element={<CBCTEducation/>}/>
            <Route path='/contact-us' element={<PatientContactUs/>}/>
            <Route path='/privacy-policy' element={<PrivacyPolicy/>}/>
            <Route path='/site-map' element={<SiteMap/>}/>
        </Routes>
    </BrowserRouter>
    );
}

export default App;