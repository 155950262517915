import React, { useState } from "react";
import { Authenticator, Auth } from '@aws-amplify/ui-react';
import { signIn } from 'aws-amplify/auth';
import "./Welcome.css"; 
import { useNavigate } from "react-router-dom";

function SignIn() {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const signInHandle = async () => {
        try {
            const signInResult = await signIn({
                username: email,
                password: password
            }).then(() => {
                navigate("/data");
            });
        } catch (error) {
            switch (error.message) {
                case 'Username should be either an email or a phone number.':
                    setErrorMessage(error.message);
                break;
                case 'Password did not conform with policy: Password not long enough':
                    setErrorMessage(error.message);
                break;
                case 'User is not confirmed.':
                    setErrorMessage(error.message);
                break;
                case 'Incorrect username or password.':
                    setErrorMessage(error.message);
                break;
                case 'User does not exist.':
                    setErrorMessage(error.message);
                break;
                default:
                    setErrorMessage("Oops something went wrong");
            }
        }
    }

    return (
        <div id="root">
            <div className="wrapper">
                <div className='container' id="content" >
                    <div className="md-3" id="messages">
                        <label className="contentTitle">Welcome Admin</label>
                    </div>
                    <div className="inputContainer" id="">
                        { errorMessage && <div className="errorLog">
                            <p className="errorLog">{errorMessage}</p>
                        </div> }
                        <div>
                            <div>
                                <input id="email-input"
                                value={email}
                                placeholder="Email"
                                onChange={(ev) => setEmail(ev.target.value)}
                                className={'inputBox'}
                                required
                                />
                            </div>
                            <br />
                            <div>
                                <input id="pass-input"
                                type="password"
                                value={password}
                                placeholder="Password"
                                onChange={(ev) => setPassword(ev.target.value)}
                                onKeyDown={e => e.key === 'Enter' ? signInHandle() : ''}
                                className={'inputBox'}
                                required
                                />
                            </div>
                            <br />
                            <div id="cred_buttons" >
                                <div>
                                    <button type="button" onClick={signInHandle} value={'Log in'} className="logInButton">Log In</button>
                                </div>
                                {/* <div>
                                    <button type="button" onClick={handleFormSubmission} value={'Sign up'} className="logInButton">Sign Up</button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="footer">
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SignIn;