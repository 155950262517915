import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./PatientMain.css";
import { FaCopy } from "react-icons/fa6";
import logo from '../assets/CAROLINA.png';


function ContactUs() {
    const navigate = useNavigate();
    const [dimensions, setDimensions ] = React.useState(window);
    const [account, setAccount] = React.useState(false);
    const [colorMode, setColorMode] = React.useState(false);

    useEffect(() => {

        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "scroll"
        };
    }, []);

    const handleAccount = () => {
        setAccount(!account);
    };

    const onChangeColorMode = () => {
        setColorMode(!colorMode);
    }

    const copyEmail = (val) => {
        var aux = document.createElement("input");
        aux.setAttribute("value", val);
        document.body.appendChild(aux);
        aux.select();
        document.execCommand("copy");
        document.body.removeChild(aux);
    };
    
    return (
        <div id="body" data-theme={colorMode}>
            <div className="page">
                <div className="navbar">
                    <div className="navbarImage" style={{width: dimensions.innerWidth/3}}>
                        <img style={{height: '105px', width: '250px'}} src={logo}></img>
                    </div>
                    <div className="navBarActions" style={{width: dimensions.innerWidth/3}}>
                        <button style={{fontFamily: "Poppins, arial, sans-serif"}} className="navbarButton" onClick={() => navigate("/")}>Home</button>
                        <button style={{fontFamily: "Poppins, arial, sans-serif"}} className="navbarButton" onClick={() => navigate("/about-us")}>About Us</button>
                        <button style={{fontFamily: "Poppins, arial, sans-serif"}} className="navbarButton" onClick={() => navigate("/patient-referral")}>Patient Referral</button>
                        <button style={{fontFamily: "Poppins, arial, sans-serif"}} className="navbarButton" onClick={() => navigate("/services")}>Services</button>
                        <button style={{fontFamily: "Poppins, arial, sans-serif"}} className="navbarButton" onClick={() => navigate("/contact-us")}>Contact Us</button>
                    </div>
                </div>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div className="mb-3" style={{width: '700px'}} >
                        <label id="patientReferralTitle">Contact Us</label>
                        <div id="page_selection">
                            <div className="twoColumnContainer">
                                <div className="column">
                                    <label id="formSubLabel">Email Address</label>
                                    <button 
                                    id="formEmailLabel" 
                                    onClick={() => copyEmail("bhowerton@carolinaomfimaging.com")}>
                                        bhowerton@carolinaomfimaging.com
                                        <FaCopy />
                                    </button>
                                    <button 
                                    id="formEmailLabel" 
                                    onClick={() => copyEmail("ncolvin@carolinaomfimaging.com")}>
                                        ncolvin@carolinaomfimaging.com
                                        <FaCopy />
                                    </button>
                                    <br></br>
                                    <div className="column">
                                        <label id="formSubLabel" >Call or Text</label>
                                        <label id="formPhoneLabel" className="form-label">Dr. Howerton at (252)-430-4430</label>
                                        <label id="formPhoneLabel" className="form-label">Dr. Colvin at (678)-995-2805</label>
                                    </div>
                                </div>
                                <div className="column">
                                    <label id="formSubLabel" className="form-label">Office Hours</label>
                                    <label id="formHoursLabel" className="form-label">Monday - Thursday</label>
                                    <label id="formHoursLabel" className="form-label">9:00am - 5:00pm</label>
                                    <br></br>
                                    <label id="formHoursLabel" className="form-label">Friday</label>
                                    <label id="formHoursLabel" className="form-label">9:00am - 12:00pm</label>
                                    <br></br>
                                    <label id="formHoursLabel" className="form-label">The office is closed for lunch between</label>
                                    <label id="formHoursLabel" className="form-label">12:00pm - 1:00pm.</label>
                                </div>
                            </div>
                        </div>
                        <div className="footer">
                            <label className="footerButton">Copyright © Carolina OMF Imaging - All Rights Reserved.</label>
                            <button className="footerButton" onClick={() => navigate("/privacy-policy")}>Privacy Policy</button>
                            <button className="footerButton" onClick={() => navigate("/site-map")}>Sitemap</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUs;